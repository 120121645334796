import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import { Lazyload } from 'vant';
Vue.use(Lazyload);

export default new Router({
  routes: [
    {
      path: '/',
      component: () => import('../components/Index.vue'),
      meta: {
        title: '帮助中心'
      }
    },
    {
      path: '/list',
      component: () => import('../components/List.vue'),
      meta: {
        title: '咨询解答'
      }
    }
  ]
});